<template>
  <AppLoginPageLayout>
    <h1 class="login__title text-center">
      Reset your password
    </h1>
    <p class="login__content mt-2 text-center">
      Create your new password below
    </p>
    <v-form
      class="mt-8"
      @submit.prevent="confirmResetPassword">
      <label class="sourcery__form">
        New Password*
        <v-text-field
          v-model="password.newPassword"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :error="!!newPasswordErrors.length"
          :error-messages="newPasswordErrors"
          :height="48"
          autocomplete="new-password"
          class="mt-2"
          :type="showNewPassword ? 'text' : 'password'"
          dense
          outlined
          single-line
          @click:append="showNewPassword = !showNewPassword" />
      </label>

      <label class="sourcery__form">
        Confirm Password*
        <v-text-field
          v-model="password.repeatPassword"
          :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :error="!!repeatPasswordErrors.length"
          :error-messages="repeatPasswordErrors"
          :height="48"
          autocomplete="new-password"
          class="mt-2"
          :type="showConfirmPassword ? 'text' : 'password'"
          dense
          outlined
          single-line
          @click:append="showConfirmPassword = !showConfirmPassword" />
      </label>

      <SignUpAgreeTermsConditions
        :agree-terms-conditions.sync="agreeTermsConditions" />

      <v-btn
        :disabled="isResetBtnDisabled"
        type="submit"
        color="blue"
        class="mt-6 white--text elevation-0 sourcery__big-pill"
        block>
        RESET PASSWORD
      </v-btn>
    </v-form>
  </AppLoginPageLayout>
</template>
<script>
import {
  required,
  sameAs,
  minLength,
} from 'vuelidate/lib/validators';
import { Auth } from 'aws-amplify';
import AppLoginPageLayout from '@/components/App/AppLoginPageLayout';
import { mapMutations } from 'vuex';
import SignUpAgreeTermsConditions from '@/components/SignUp/SignUpAgreeTermsConditions';
import ErrorsText from '@/constants/errors';
import API from '@/services/graphql';
export default {
  name: 'CompleteNewPassword',
  components: {
    AppLoginPageLayout,
    SignUpAgreeTermsConditions,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    errorWithCode: null,
    showNewPassword: false,
    showConfirmPassword: false,
    agreeTermsConditions: true,
    password: {
      newPassword: null,
      repeatPassword: null,
    },
  }),
  validations: {
    password: {
      newPassword: {
        required, minLength: minLength(8),
      },
      repeatPassword: {
        required, sameAs: sameAs('newPassword'),
      },
    },
  },
  computed: {
    verificationCodeErrors() {
      const errors = [];
      if (!this.$v.password.code.$dirty) return errors;
      if (!this.$v.password.code.minLength) {
        errors.push('Verification code must be at 6 characters long');
      }
      if (!this.$v.password.code.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.errorWithCode) {
        errors.push(this.errorWithCode);
      }
      return errors;
    },
    isResetBtnDisabled() {
      return !this.agreeTermsConditions || !this.password.newPassword || !this.password.repeatPassword;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.password.newPassword.$dirty) return errors;
      if (!this.$v.password.newPassword.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (!this.$v.password.newPassword.minLength) {
        errors.push('Password must be with 8 characters long');
      }
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.password.repeatPassword.$dirty) return errors;
      if (!this.$v.password.repeatPassword.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (!this.$v.password.repeatPassword.sameAs) {
        errors.push('Confirm Password must be the same');
      }
      return errors;
    },
  },
  created() {
    if (!this.user) {
      this.$router.push('login');
    }
  },
  methods: {
    ...mapMutations(['spinner']),
    async confirmResetPassword() {
      this.$v.password.$touch();
      if (!this.$v.password.$invalid) {
        this.spinner(true);
        try {
          await Auth.completeNewPassword(
            this.user,
            this.password.newPassword,
            this.user.challengeParam.requiredAttributes,
          );
          const { query } = this.$route;
          await API.acceptTermsOfService();
          await API.verifyEmail();
          if (query && query.hasOwnProperty('forcepay')) {
            this.$router.push({
              name: 'payment', query,
            });
          } else {
            this.$router.push({
              name: 'projects',
            }).catch(() => {
            });
          }
        } catch (err) {
          console.log('err', err);
          this.spinner(false);
        }
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
